body{
  min-height: 100vh;
}

.input-ctn {
  width: 100%;
  margin: 10px auto;
  
}
.search-input {
  width: 100%;
}

.page-ctn {
  min-height: 100vh;
  /* min-height: 100px; */
  width: 100%;
  margin: 0 auto 30px auto;
  background-color: #F3F6F9;
  /* overflow-x: hidden; */
}

.replace-ctn{
  min-height: 100vh;
  width: 100%;
  margin: 0px auto 0px auto;
  background-color: #F3F6F9;
  padding-bottom: 30px;
}

.page-wrapper{
  background-color: #F3F6F9;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto 0px auto;
  padding-top: 60px;
  

}

@media only screen and (min-height: 800px) {
  .page-wrapper{
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .page-ctn {
    width: 95%;
  }

  .replace-ctn{
    width: 95%;
    margin-bottom: 40px;
  }
}

.year-ctn:nth-of-type(odd) {
  background-color: #f5f5f5;
}

.year-ctn {
  /* border-bottom: 1px solid #ccc; */
  /* padding: 5px 5px; */
  border-top: 1px solid #ccc;
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
}

.year-ctn:last-of-type {
  border-bottom: 1px solid #ccc;
}

.bold {
  font-weight: bold;
}

.aims-item::before {
  content: "•";
  margin-right: 5px;
}

.aims-item {
  margin-top: 5px;
  margin-bottom: 5px;
}

.hidden {
  display: none;
}

.lo-title {
  margin-top: 15px;
  margin-bottom: 3px;
  font-weight: bold;
  /* font-size: 1.2em; */
}

.lo-content {
  margin-left: 10px;
}


.lo-subtitle {
  margin-left: -10px;
}
h4 {
  margin-top: 30px;
  margin-bottom: 5px;
}

.button {
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  text-align: center;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}

p {
  margin-top: 0px;
  margin-bottom: 0px;
}



ul {
  margin-top: 5px;
  margin-bottom: 5px;
}
.group-table-ctn {
  margin-top: 10px;
}

.group-ctn {
  margin-left: 15px;
}
a {
  color: #0069c2;
}

a:hover {
  text-decoration: none;
}

/* .detail-ctn > span, p, div{
  font-family: inherit !important;
  font-size: inherit !important;
} */

.btn-ctn {
  width: 100%;
  margin: 30px auto;
}

.submit-btn {
  width: 100%;
  height: 40px;
}

.inputBox {
  width: 100%;
  margin-bottom: 10px !important;
  /* margin-right: 20px !important; */
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
}

.inputButton {
  width: 100%;
  height: 54px;
  float: left;

  background-color:  rgb(0, 155, 189, 1) !important;
  color: white !important;
}

.back-button {
  width: 100%;
  height: 54px;
  float: left;

  background-color:  rgb(0, 155, 189, 1) !important;
  color: white !important;
  margin-bottom: 2% !important;
}

@media only screen and (min-width: 600px){
  .inputButton {
    position: relative;
    left: 20%;
  }
}


.inputButton:hover{
  background-color: rgb(0, 155, 189, 0.7) !important;
}

.page-caption{
  color: rgba(27, 42, 107, 0.9);
}

.card-caption{
  color:white;
}

/* .page-caption:hover{
  color: white;
} */

.select-block-left{
  width: 96%;
  float: left;
}
.select-block-right{
  width: 96%;
  float: right;
  margin-right: 2%;
}

@media only screen and (max-width: 600px) {
  .select-block-right{
    float: left;
    width: 100%;
  }
  .select-block-left{
    width: 100%;
  }
}


.select-button{
  height: 54px;
  background-color: rgb(0, 155, 189, 0.8) !important;

  color: white  !important;
  position: relative;
  top: 20px;
  width: 100%;
}

.select-button:hover{
  background-color: rgba(0, 155, 189, 1) !important;
}

.select-card{
  width: 95%;
  min-height: 350px;
  position: relative;
  top:15px;
  background-image: linear-gradient(250deg, rgba(27, 42, 107, 0.8), rgba(27, 42, 107, 0.72)); 
  /* background-image: linear-gradient(250deg, rgba(0, 86, 151, 0.6), rgba(0, 86, 151, 0.4));  */

  padding: 2%;
  border-radius: 8px;
  box-shadow: 4px 4px 20px rgba(27, 42, 100, 0.4);
  z-index: 10 !important;
  /* overflow:scroll; */
}

.page-title{
  width: 125px;
  height: auto;
  color: rgb(27, 42, 107);
  margin: 30px 0px;
  text-decoration: underline;
  /* border-bottom: rgb(27, 42, 107) solid; */
}

.detail-page-ctn {
  margin-top: 30px;
  margin-bottom: 60px;
}

.search-card{
  width: 94%;
  height: auto;
  background-image: linear-gradient(250deg, rgba(27, 42, 107, 0.8), rgba(27, 42, 107, 0.7)); 
  padding: 4%;
  padding-top: 2%;
  border-radius: 8px;
  box-shadow: 4px 4px 20px rgba(27, 42, 100, 0.2);
}

@media only screen and (max-width: 600px) {
  .search-card{
    width: 98%;
    padding: 2% 2%;
  }
}

.search-field{
  margin-top: 3%;
  width: 98%;
}



.about-page-wrapper{
  padding: 50px;
  color: rgba(27, 42, 107, 1);
}

.about-page-wrapper > p, li {
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px){
  .about-page-wrapper{
    padding: 50px 0px;
  }
}

.about-btn-ctn {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    gap: 5px;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.supportFromHome {
  bottom: 15px;
}

