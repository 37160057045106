.nav-caption{
    color: rgba(255, 255, 255, 0.9);
    font-size: 19px;
    font-weight: bold !important;
}


.page-button:hover{
    border-bottom: 2px solid rgba(27, 42, 107, 0.8);
    border-radius: 0;
}