.index-page-wrapper {
    background-color: #F3F6F9;
    width: 100%;
    margin: 0 auto 0px auto;
    padding-top: 50px;
  }

  .index-page-wrapper > p {
    margin-bottom: 15px;
  }
  
  .index-ctn{
    margin: 0 40px;
    height: auto;
    min-height: 100vh;
    width: 100%;
    /* margin: 0px auto 0px auto; */
    background-color: #F3F6F9;
    padding-bottom: 30px;
  }

  .index-streamer{
    position: absolute;
    right: 50px;
    top: 95px;
    height: 100px;
    width: 100%;
    font-size: 120px;
    font-weight: 900;
    /* background-color: #0069c2; */
  }

  .streamer-caption{
    transform: rotate(0.25turn);
    position: absolute;
    top: 350px;
    right: -400px;
    color: rgba(27, 42, 107, 0.5);
  }

  .caption-wrapper{
    width: 480px;
    /* height: 350px; */
    /* background-color: #0069c2; */
    text-align: left;
    margin-top: 5px;
  }

  .index-caption{
    font-size: 64px;
    /* width: auto; */
    /* height: auto; */
    position: relative;
    text-align: left;
    font-weight: 900;
    color: rgba(27, 42, 107, 1);
  }

  .subtitle-wrapper{
    width: 480px;
  }

  .index-subtitle{
    text-align: left;
    position: relative;
    margin: 10px 0px 30px 0;
    font-size: 20px;
    font-weight: 400;
    /* padding: 0 16%; */
    z-index: 100;
    color: rgba(27, 42, 107, 0.8);
    /* background-color: #0069c2; */
  }

  .index-card{
    width: 660px;
    height: 100px;
    /* background-color: #0069c2; */
    background-image: linear-gradient(250deg, rgba(27, 42, 107, 0.4), rgba(27, 42, 107, 0.5)); 
    margin: 20px 0px;
    border-radius: 8px;
    box-shadow: 4px 4px 20px rgba(27, 42, 120, 0.15);
  }

  .index-content{
    padding: 10px 8px;
    width: 420px;
    font-size: 20px;
    color: white;
    position: relative;
    bottom:6px;
    left:74px;
  }

  .my-card{
    margin-top: 20px;
    height: 120px;
  }
  

  .index-button{
    position: relative;
    bottom: 52px;
    left: 540px;
  }
  
  .my-button {
    position: relative;
    bottom: 65px;
  }
  
  .plan-button{
    position: relative;
    bottom: 50px;
  }

  .index-icon{
    float: left;
    position: absolute;
    /* bottom: 220px; */
    top: -30vh;
    left: 30vw;
    z-index: 0;
  }


  
  .content-bullet{
    position: relative;
    top: 35px;
    margin: 0 15px;
  }

  .my-bullet{
    position: relative;
    top: 48px;
  }

.cards-wrapper{
    width: 660px;
}

@media only screen and (max-width: 840px){
  .index-streamer{
    display: none;
  }

  @media only screen and (max-width: 1000px){
    .index-icon{
        position: absolute;
        left: 0;
      }
  }

  @media only screen and (max-width: 830px){
    .icon-wrapper{
        display: none;
      }
  }

  
  @media only screen and (max-width: 600px){
    .index-ctn{
      margin: 0;
    }
    .caption-wrapper{
      width: 80%;
      height: auto;
      /* background-color: aqua; */
      margin-left: 10%;
      margin-top: 30px;
    }

    .index-caption{
      font-size: 40px;
      text-align: center;
    }

    .subtitle-wrapper{
      width: 100%;
      height: auto;
      margin-top: 15px;
      /* background-color: blue; */
    }

    .index-subtitle{
      font-size: 18px;
      width: 80%;
      margin: 0;
      margin-left: 10%;
      text-align: center;
    }

    .cards-wrapper{
      margin-left: 2%;
      width: 96%;
      height: auto;
      /* background-color: aquamarine; */
    }

    .index-card{
      width: 100%;
      /* height: 80px; */
      height: auto;
    }

    .index-button{
      position: relative;
      /* right: 0px; */
      left: 2%;
      top: -5px;
      width: 96%;
      margin-bottom: 5px !important;
    }

    .index-content{
      font-size: 18px;
      width: 92%;
      left:2%;
      text-align: center;
      /* background-color: aqua; */
    }
    .content-bullet{
      position: relative;
      top: 4px;
      left: 48%;
      /* top: 35px; */
      margin: 3px 0;
      /* margin: 0 15px; */
    }

    /* .index-content{
      padding: 10px 8px;
      width: 420px;
      font-size: 20px;
      color: white;
      position: relative;
      bottom:6px;
      left:74px;
      }
      .index-card{
        width: 660px;
        height: 100px;
        background-image: linear-gradient(250deg, rgba(27, 42, 107, 0.4), rgba(27, 42, 107, 0.5)); 
        margin: 20px 0px;
        border-radius: 8px;
        box-shadow: 4px 4px 20px rgba(27, 42, 120, 0.15);

    } 
      .content-bullet{
    position: relative;
    top: 35px;
    margin: 0 15px;
  }
    */
  }
}