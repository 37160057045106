
.MuiDataGrid-row:nth-of-type(even) {
  background-color: #e7edf3;
}

.MuiDataGrid-row:nth-of-type(odd) {
  background-color: #f4f6f9;
}

.MuiDataGrid-columnHeaders {
  background-color: #e7edf3;
  
}

/* .MuiDataGrid-columnHeaderTitle {
  
  white-space: initial !important;
  text-align: center;
} */

.MuiDataGrid-cell {
  white-space: initial !important;
  text-align: center;
}

.MuiDataGrid-cellContent {
  padding-top: 10px;
  padding-bottom: 10px;
}